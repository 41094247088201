import React from 'react';

import styles from '../css/gre.module.css';
import CloseWindowButton from './CloseWindowButton';
import Header from './Header';

const Error = (props: {}) => {

    return (
        <div>
            <Header headerText={"Improve equity in research"}/>
            <main>
                <div className={`${styles.errorScreenContent}`}>
                    <div className={`${styles.headerSubTextContent}`}>We’re sorry. Something has gone wrong.</div>
                    <div className={`${styles.sectionSubText}`}>Please close this window and try again later.</div>
                </div>
                <div className={`${styles.errorScreenDialogButtons}`}>
                    <CloseWindowButton autoclose={false}/>
                </div>
            </main>
        </div>
    )
}

export default Error