import React from "react";
import GREDialog from "./components/GREDialog";
import Error from "./components/Error"
import {Switch, Route} from "react-router-dom";
import {Helmet} from "react-helmet";

function App() {

    let Router;
    if (typeof document !== "undefined") {
        Router = require("react-router-dom").BrowserRouter;
    } else {
        Router = require("react-router-dom").StaticRouter;
    }

    const aaScript = window.location.host.indexOf('stg') !== -1 ||
        window.location.host.indexOf('np') !== -1?"//assets.adobedtm.com/4a848ae9611a/44fa06ff7349/launch-cbc38d8a9f43-development.min.js":
        "//assets.adobedtm.com/4a848ae9611a/44fa06ff7349/launch-448b800fff56.min.js";

    return (
        <>
            <Helmet>
                <script src={`${aaScript}`} type="text/javascript" />
            </Helmet>
            <Router>
                <Switch>
                    <Route path={["/error",]}>
                        <Error/>
                    </Route>
                    <Route path="/" >
                        <GREDialog name="gre"/>
                    </Route>
                </Switch>
            </Router>
        </>
    );
}

export default App;
