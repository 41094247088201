import React from 'react';

import styles from '../css/gre.module.css';

const Loading = (props: {headerText:string|undefined}) => {

    return (
        <header className={`${styles.esHeaderBanner}`}>
            <img src="nonsolus-elsevier-logo.svg" alt="Non solus logo"/>
            <h1 className={`${styles.esDialogHeader} ${styles.esHeadingH3}`}
                id="dialog-complex1-label">
                {props.headerText}
            </h1>
        </header>
    )
}

export default Loading