import React from 'react';
import { ErrorOption, FieldErrors, FieldPath, FieldValues, UseFormGetValues } from 'react-hook-form';

import { trackEvent } from '../analytics/aa-utils';
import styles from '../css/gre.module.css';

const WindowDialogButton = (questionnaire: any[],
                            currentSection: number,
                            getValues: UseFormGetValues<FieldValues>,
                            setError: (name: FieldPath<FieldValues>, error: ErrorOption, options?: { shouldFocus: boolean }) => void,
                            setCurrentSection: (value: (((prevState: number) => number) | number)) => void,
                            errors: FieldErrors<FieldValues>,
                            initialSaveButtonState: boolean,
                            isSubmitting: boolean) => {

    return (
        <button className={`${styles.esButton} ${styles.primary} ${styles.small}`}
            onClick={(e) => {
            let buttonType = currentSection < questionnaire.length - 3 ? 'nextQuestion' : 'saveResponse'
            trackEvent({
                eventType: 'buttonClick',
                buttonType: buttonType
            });

            if (Array.from(getValues(questionnaire[currentSection].sectionId || '')).indexOf('Self describe') !== -1) {
                const val: string = getValues('selfDescribe-' + questionnaire[currentSection].sectionId || '')
                if (val === undefined || val.trim().length === 0) {
                    setError('selfDescribe-' + questionnaire[currentSection].sectionId || '', {
                        type: 'custom',
                        message: 'Enter a value'
                    })
                    return false
                }
            }

            let lastQuestionSection = currentSection == questionnaire.length - 3
            if (!lastQuestionSection){
                setCurrentSection(prevState => prevState + 1)
            } else {
                setCurrentSection(questionnaire.map(function(e) { return e.sectionId; }).indexOf('thankyou'));
            }
        }}
                disabled={Object.keys(errors).length > 0 || initialSaveButtonState || isSubmitting}
                type="submit">
            <span>{currentSection < questionnaire.length - 3 ? "Next question" : "Submit your responses"}</span>
            <svg focusable="false" height="24" viewBox="0 0 54 128" width="24">
                <path d="M1 99l38-38L1 23l7-7 45 45-45 45z"/>
            </svg>
        </button>
    );
}

export default WindowDialogButton