import 'isomorphic-fetch';

const tenMinutesInMills = 600000
const envValueKey = "env_values"
const envValueTimeKey = "env_values_time"

export const getBaseUrl = async () => {
    let baseUrl = `${window.location.protocol}//${window.location.host}`
    let backendUri = await getEnv("backendUri")
    let env = await getEnv("environment");
    if (env === "local") {
        return backendUri
    }
    return baseUrl
}

export const getBackendUrl = async () => {
    let baseUrl = `${window.location.protocol}//${window.location.host}/diversity-service/api`
    let backendUri = await getEnv("backendUri")
    let env = await getEnv("environment");
    if (env === "local") {
        return backendUri
    }
    return baseUrl
}

export const getEnv = async function (key: string) {

    let envValues = localStorage.getItem(envValueKey)
    let envValuesTime = localStorage.getItem(envValueTimeKey)

    let now = new Date();

    let regrabEnv = false

    if (envValues == null || envValuesTime == null) {
        console.log("No previous env values")
        regrabEnv = true
    } else {
        //Expire env values after 10 minutes
        if (parseInt(envValuesTime) + tenMinutesInMills < now.getTime()) {
            console.log("Expired previous env values")
            regrabEnv = true
        }
    }

    if (regrabEnv) {
        console.log("Regrabbing env values")

        return fetch("/env.json")
            .then(v => v.json())
            .then(v => {
                localStorage.setItem(envValueKey, JSON.stringify(v))
                localStorage.setItem(envValueTimeKey, now.getTime().toString())
                let val = v[key]
                if (val === undefined) {
                    throw new Error("key [" + key + "] could not be found")
                }
                return val
            }).catch((e: any) => {
                console.error("Failed to get env values!")
                console.error(e)
                unsetOnError()
                return ""
            })
    }

    try {
        let env = JSON.parse(localStorage.getItem(envValueKey) || "{}")
        let val = env[key]

        if (val === undefined) {
            throw new Error("key [" + key + "] could not be found")
        }

        return val.toString()

    } catch (e: any) {
        console.error("Failed to JSON parse env values from localstorage")
        console.error(e)
        unsetOnError()
    }
}

function unsetOnError() {
    localStorage.removeItem(envValueKey)
    localStorage.removeItem(envValueTimeKey)
}