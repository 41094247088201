import 'isomorphic-fetch';

import { GreApi, PutOptionsRequest, UserInfo } from '@tnt/gre-api-ts-client';

import { getBackendUrl } from './env';

async function setupGreApi(accessToken: string) {
    const backendUrl = await computeBackendUrl();
    const greApi = new GreApi(backendUrl);
    greApi.accessToken = accessToken;
    return greApi;
}

export const getQuestionnaire = async (userInfo: UserInfo, questionSet: string, accessKey: string) => {
    if(accessKey === ""){
        console.error("Access token is empty: cannot get questionnaire")
        return
    }

    const greApi = await setupGreApi(accessKey);
    let response = await greApi.initializeQuestionnaireWithResponses(questionSet, userInfo)
    return response.body

}

export const saveQuestionnaireResponse = async (requestBody: PutOptionsRequest, questionSet: string, accessKey: string) => {
    if(accessKey === ""){
        console.error("Access token is empty: cannot submit answer.")
        return
    }

    const greApi = await setupGreApi(accessKey);
    let response = await greApi.updateQuestionnaireResponses(questionSet, requestBody)
    return response.body

}

async function computeBackendUrl() {
    return process.env.PACT_BROKER_URL === undefined ? getBackendUrl() : "http://localhost:8080";
}

/**
 * https://ext-diversity.tnt-np.elsevier.com/
 ?flowPoint=login&peopleId=5607&questionSet=195d8c7a-6f57-4de2-9912-931cdf34fbcc&elsJournalCode=JFUE&emJournalCode=english04test_170
 */
export const parseQueryParameters = () => {
    console.log("Parsing query params: " + window.location.search)
    let queryStr = new URLSearchParams(window.location.search)
    let peopleId = queryStr.get("peopleId") || "";
    if (peopleId !== "") {
        sessionStorage.setItem("peopleId", peopleId);
    }

    let flowPoint = queryStr.get("flowPoint") || "";
    if(flowPoint !== ""){
        sessionStorage.setItem("flowPoint", flowPoint);
    }

    let questionSet = queryStr.get("questionSet") || "";
    if(questionSet !== ""){
        sessionStorage.setItem("questionSet", questionSet);
    }

    let emJournalCode = queryStr.get("emJournalCode") || "";
    if(emJournalCode !== ""){
        sessionStorage.setItem("emJournalCode", emJournalCode);
    }
}