import { getEnv, getBackendUrl } from "./env";

export async function httpRequests<T>(
    url: RequestInfo,
    init?: RequestInit | undefined
): Promise<T> {
    let data
    const response = await fetch(url, init);
    if (response.status<400) {
        data = await response.json();
        return data;
    } else {
        throw new Error("API call went wrong" + await response.json())
    }
}

export const oauth2 = async () : Promise<string> => {
    const urlParams = new URLSearchParams(window.location.search);

    let code = urlParams.get("code") || ""
    let oauth2Error = urlParams.get("error") || ""

    if (oauth2Error !== "") {
        let oauth2ErrorDesc = urlParams.get("error_description") || ""
        return new Promise<string>(((resolve, reject) => {
            const msg = "OAuth2 error: " + oauth2Error + "\n" + oauth2ErrorDesc
            console.error(msg)
            reject(msg)
        }))
    }

    let shouldCallAuthorize = () => {
        return code === ""
    }

    let shouldCallToken = () => {
        return code !== ""
    }

    if (shouldCallAuthorize()) {
        console.log("Calling authorize for oauth2 step 1/2.")
        return authorize()
    }
    else if (shouldCallToken()) {
        console.log("Calling token for oauth2 step 2/2.")
        return token(code)
    } else {
        return new Promise<string>(((resolve, reject) => {
            reject("URL Params mismatched for oauth flow.")
        }))
    }
}

export const authorize = async () : Promise<string> => {
    return new Promise<string>((async (resolve, reject) => {

        const params = new URLSearchParams(await getOauthConnData(""));
        // Remove code from the url params. It will be null in authorize, its what we are trying to obtain.
        params.delete("code")

        const EMJournalCode = new URLSearchParams(window.location.search).get("emJournalCode")
        if (EMJournalCode == null) {
            const msg = "No EM journal code found in URL: " + window.location.toString() + "\t EMJournalCode required for auth"
            console.error(msg)
            reject(msg)
        }

        let oauthUri = await getEnv("oauthUri");
        let emAuthorizeUrl = oauthUri + "/" + EMJournalCode + "/oauth2/authorize?" + params.toString()
        window.location.assign(emAuthorizeUrl);
        console.log("successfully redirected to authorize endpoint.")
        return false;
    }))
}

export const token = async (code: string) : Promise<string> => {
    return new Promise<string>((async (resolve, reject) => {
        let backendUri = await getBackendUrl();
        let oauthURI = backendUri + "/v1/token"
        const body = await getOauthConnData(code)
        try {
            // Request GRE backend to append client secret and return jwt
            let response = await httpRequests<TokenResponse>(oauthURI, {
                method: 'POST',
                body: JSON.stringify(body),
            });

            if (response.access_token) {
                resolve(response.access_token)
            } else {
                reject("Response from token endpoint not include access")
            }
        } catch (e: any) {
            reject("Unable to acquire oauth token from code : " + e.message)
        }
        reject("Failed to accquire oauth token.")
    }))
}

async function getOauthConnData(code: string) {
    let oauth2ClientId = await getEnv("oauth2ClientId");

    return {
        grant_type: "authorization_code",
        client_id: oauth2ClientId,
        scope: "diversity-reporting",
        response_type: "code",
        redirect_uri: window.location.origin,
        code: code,
    };

}

export const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

class TokenResponse {
    "access_token"?: string
    "token_type"?: string
    "expires_in"?: number
}
