/*
   Adobe analytics is bound to window on page init,
   if not there for any reason, ensure graceful page load anyway
*/

if (typeof window !== 'undefined') {

    window.pageDataTracker = window.pageDataTracker
        || {
            trackEvent: () => {
            }, trackPageLoad: () => {
            }
        };
}
// Constants
const productCode = 'DIV';
const buttonClick = 'buttonClick';
const newPage = 'newPage';

const environment = window.location.host.indexOf('stg') !== -1?"staging":
    window.location.host.indexOf('np') !== -1?"nonprod":"prod";


const getNormalisedEventType = (eventType) => (
    eventType === newPage
        ? newPage : eventType
);

const getAdobeAnalyticsFirstPageData = (
    analyticsInfo,
    type = 'gre:home:start',
    name = 'greQuestionPage',
) => ({
    page: {
        businessUnit: 'ELS:RP:ST',
        productName: productCode,
        environment: environment,
        name,
        type,
        language: 'en',
    },
    visitor: {
        ...analyticsInfo,
    },
});

const getAdobeAnalyticsPageData = (
    analyticsInfo, name, type
) => ({
    page: {
        businessUnit: 'ELS:RP:ST',
        productName: productCode,
        environment: environment,
        name,
        type,
        language: 'en',
    },
    visitor: {
        ...analyticsInfo,
    },
});

export const trackFirstPageLoad = (analyticsInfo, journal) => {
    // Adobe analytics relies on a "window" bound pageData variable
    window.pageData = {...getAdobeAnalyticsFirstPageData(analyticsInfo), journal};
    window.pageDataTracker.trackPageLoad();
};

export const trackPageLoad = (analyticsInfo, name, type) => {
    window.pageData = {...getAdobeAnalyticsPageData(analyticsInfo, name, type)};
    window.pageDataTracker.trackPageLoad();
}

/*
  See documentation here for further details on expected payloads:
  https://confluence.cbsels.com/display/AA/All+Requirements
*/
const getEventTrackingpayload = (eventType, payload) => {
    switch (eventType) {
        case buttonClick: {
            return {
                buttonType: `${payload.buttonType}${payload.buttonName
                    ? `:${payload.buttonName}` : ''}`,
            };
        }
        //default:
        case newPage: {
            return {
                page:
                    { name: payload.pageName },
            };
        }
    }
};

// By default events are 'newPage' events
export const trackEvent = (payload = {}) => window.pageDataTracker.trackEvent(
    getNormalisedEventType(payload.eventType || newPage),
    getEventTrackingpayload(payload.eventType || newPage, payload)
);

// Constants for "trackEvent" integrations
export const adobeAnalyticConstants = {
    buttonClick, productCode, newPage
};