import { QuestionnaireResponse, UserInfo } from '@tnt/gre-api-ts-client';
import HTMLReactParser from 'html-react-parser';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { saveQuestionnaireResponse } from '../api/backendApi';
import styles from '../css/gre.module.css';
import CloseWindowButton from './CloseWindowButton';
import Header from './Header';
import RenderQuestion from './RenderQuestion';
import WindowDialogButton from './WindowDialogButton';
import Footer from "./Footer";

const selfDescribePrefix = 'Self describe|';

function extractFreeText(defaultVal: { gender: string; ethnicity: string[]; race: string[] }, key: string) {
    // @ts-ignore
    defaultVal[key]?.forEach(function (item: string, i: number) {

        if (item.includes(selfDescribePrefix)) {
            const freeText = item.split('|')[1]
            if (freeText.length > 0) {
                // @ts-ignore
                defaultVal['selfDescribe-' + key] = freeText
                // @ts-ignore
                defaultVal[key][i] = 'Self describe';
            }
        }
    });
}

const getInitialValues = (questionnaire: any[],
                          setInitialSaveButtonState: (value: (((prevState: boolean) => boolean) | boolean)) => void) => {
    let defaultVal = {gender: "", ethnicity: [], race: []};
    questionnaire.map(section => {
        for (let i = 0; section.questionsList !== undefined && i < section.questionsList.length; i++) {
            let key: string = section.questionsList[i].questionName || "";

            if (key !== "") {

                if (section.questionsList[i].questionType === 'radio' && section.questionsList[i].previousResponse?.length > 0) {
                    // @ts-ignore
                    defaultVal[key] = section.questionsList[i].previousResponse[0];
                    setInitialSaveButtonState(false)

                } else {
                    // @ts-ignore
                    defaultVal[key] = section.questionsList[i]?.previousResponse;
                    extractFreeText(defaultVal, key)
                }
            }
        }
        return "";
    });
    return defaultVal;
}



const GRERegistrationForm = (props: {
    children: { setShowThankyouScreen: Dispatch<SetStateAction<boolean>>; };
    showThankyouScreen: boolean, showElsAckScreen:boolean, data: QuestionnaireResponse, user: UserInfo, questionSet: string, accessToken: string
}) => {

    const questionnaire = Array.from(props.data.questionnaire || []);
    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting},
        reset,
        getValues,
        setValue,
        setError,
        clearErrors
    } = useForm();

    const [currentSection, setCurrentSection] = useState(0);
    const [initialSaveButtonState, setInitialSaveButtonState] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            reset(getInitialValues(questionnaire, setInitialSaveButtonState));
        }, 500);

    }, [reset]);

    const onSubmit = useCallback((formValues) => {
        let currentSectionId = questionnaire[currentSection].sectionId
        //No need to check for "els-employee" section here, since we submit answers for them on load if identified
        if (currentSectionId === "thankyou") {
            const ethnicities: string[] = formValues['ethnicity'].map(function (x: string) {
                if (x === 'Self describe') {
                    return selfDescribePrefix + formValues['selfDescribe-ethnicity']
                }
                return x
            });

            const races: string[] = formValues['race'].map(function (x: string) {
                if (x === 'Self describe') {
                    return selfDescribePrefix + formValues['selfDescribe-race']
                }
                return x
            });

            const genderResp: string = formValues['gender']

            const payload = {gender: [genderResp], ethnicity: ethnicities, race: races}
            console.log(JSON.stringify(payload));

            //api call to post the user response
            saveQuestionnaireResponse({
                userInfo: props.user,
                ...payload
            }, props.questionSet, props.accessToken).then(res => {
                if (res) {
                    console.log("save response");
                    console.log(res.message);
                    // on success show next page
                    res.message === "Success" ? props.children.setShowThankyouScreen(true) : window.location.assign("/error");
                }
            }).catch(e => {
                console.error("Save error response")
                console.error(e)
                window.location.assign("/error")
            });

        }


    }, [props, currentSection]);

    const renderNamedSection = (sectionName:string):any => {
        return questionnaire.map(section => {
            return (
                section.sectionId === sectionName && <div key={section.sectionId}>
                    <Header headerText={section.sectionName}/>
                    <div className={`${styles.thankyouSubTextContent}`}>
                        <div className={section.sectionId === "els-employee" ? `${styles.headerSubTextContentElsEmployeeSection}`:`${styles.headerSubTextContent}`}>{HTMLReactParser(section.sectionDesc || "")}</div>
                        <div className={`${styles.sectionSubText}`}>{HTMLReactParser(section.sectionSubText || "")}</div>
                    </div>
                </div>
            )
        })
    };

    let resultRender;

    if(props.showElsAckScreen || props.showThankyouScreen){
        let contentScreen = renderNamedSection(props.showElsAckScreen ? "els-employee" : "thankyou")
        resultRender = (
            <div>
                {contentScreen}
                <div className={`${styles.esDialogButtons}`}>
                    <CloseWindowButton autoclose={props.showElsAckScreen}/>
                </div>
                <Footer/>
            </div>
        )
    } else {
        resultRender = (
            <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div key={questionnaire[currentSection].sectionId}>
                        <Header headerText={questionnaire[currentSection].sectionName}/>
                        <div className={`${styles.mainScreenContent}`}>
                            <div
                                className={`${styles.headerSubTextContent}`}>{questionnaire[currentSection].sectionDesc}</div>
                            <div
                                className={`${styles.sectionSubText}`}>{HTMLReactParser(questionnaire[currentSection].sectionSubText || "")}</div>
                        </div>
                        <div className={`${styles.mainQuestionsContent}`}>
                            <RenderQuestion questionList={questionnaire[currentSection].questionsList || []}
                                            clearErrors={clearErrors} errors={errors}
                                            register={register}key={questionnaire[currentSection].sectionId || ""}>
                                {{'setInitialSaveButtonState': setInitialSaveButtonState,
                                    'setValue': setValue
                                }}
                            </RenderQuestion>
                        </div>
                    </div>
                    <div className={`${styles.esDialogButtons}`}>
                        {WindowDialogButton(questionnaire, currentSection, getValues, setError, setCurrentSection, errors, initialSaveButtonState, isSubmitting)}
                    </div>
                </form>
                <Footer/>
            </>
        )
    }

    return (
        <>{resultRender}</>
    )
}

export default GRERegistrationForm

