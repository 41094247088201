import React from 'react';

import baseStyles from '../../css/gre.module.css';
import footerStyles from './footer.module.css';

function Footer() {
  const styles = {...baseStyles ,  ...footerStyles };

  return (
    <footer data-testid="Footer" className={styles.esFooter} id="footer" >
      <div className={styles.esFooterNonsolus}>
        <a aria-label="Elsevier homepage" className="es-js-tooltip" href="https://www.elsevier.com/"
          rel="noopener nofollow noreferrer" target="_blank">
          <img alt="Elsevier apple tree logo"
            height="80"
            src={"../nonsolus-elsevier-logo.svg"}
            width="73"
            style={{ marginBottom: "30px"}} />
        </a>
      </div>
      <div className={styles.esFooterContent}>
        <ul className={styles.esFooterLinks}>
          <li>
            <a className={styles.esLink}
              href="https://www.elsevier.com/legal/elsevier-website-terms-and-conditions"
              rel="noopener nofollow noreferrer" target="_blank">
              Terms and conditions
            </a>
          </li>
          <li>
            <a className={styles.esLink} href="https://www.elsevier.com/legal/privacy-policy"
              rel="noopener nofollow noreferrer" target="_blank">
              Privacy policy
            </a>
          </li>
          <li>
            <a className={styles.esLink} href={"https://www.elsevier.com/support/"}>
              Support and contact
            </a>
          </li>
        </ul>
        <div className="es-footer-cookie">
          We use cookies to help provide and enhance our service and
          tailor content. By continuing you agree to the&nbsp;
          <a className={styles.esLink} href="https://www.elsevier.com/legal/cookienotice"
            target="_blank" rel="noreferrer">use of cookies</a>.<br />
          All content on this site: Copyright © <time dateTime="2023">
            2023 </time>
          Elsevier B.V., its licensors, and contributors.
          All rights are reserved, including those for text and data mining,
          AI training, and similar technologies. For all open access content,
          the Creative Commons licensing terms apply.
        </div>
      </div>
      <div className={styles.esFooterRelx}>
        <a aria-label="RELX ™ homepage" className="es-js-tooltip" href="https://www.relx.com/"
          rel="noopener nofollow noreferrer" target="_blank">
          <img alt="RELX ™" height="21" src={"../relx.svg"} width="90" />
        </a>
      </div>
    </footer>
  )
}

export default Footer;
