import React, {ReactNode} from "react";
import styles from "../css/gre.module.css";
import {ResponseOption} from "@tnt/gre-api-ts-client/api";
import {FieldErrors, FieldValues} from "react-hook-form";
import {Question} from "@tnt/gre-api-ts-client";

const RadioButtonComponent = (question: Question, register: any, errors: FieldErrors<FieldValues>,
                              setInitialSaveButtonState: (value: (((prevState: boolean) => boolean) | boolean)) => void): React.ReactNode => {

    const renderRadioOptions = (options: Array<ResponseOption>, name: string): ReactNode => {
            const opt = Array.from(options)
            return opt.map((option, index) => {
                return (
                    <li key={option.option}>
                        <label>
                            <input className={`${styles.esCheckInput}`}
                                   onClick={() => setInitialSaveButtonState(false)}
                                   type="radio"
                                   value={option.option}
                                   {...register(name, {required: "Response is required"})} />
                            <span>{option.option}</span>
                        </label>
                    </li>
                )
            });
        }
    ;

    try {

        const options = question.responseOptions != null ? question.responseOptions : [];
        const name = question.questionName != null ? question.questionName : "";

        return (
            <>
                <ul>
                    {renderRadioOptions(options, name)}
                    {/*<span style={{color: "red"}}>{errors[name]?.message}</span>*/}
                </ul>
                <div className={`${styles.contentSuperSubText}`}>
                    {question.questionSuperSubText}
                </div>
            </>
        );
    } catch (e) {
        return null;
    }

}
export default RadioButtonComponent;