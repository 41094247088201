import React from "react";
import styles from "../css/gre.module.css";

const Loading = () => {

    return (
        <main className={`${styles.esHtmlBody}`}>
            <img className={`${styles.loadingLogo}`} src="nonsolus-elsevier-logo.svg" alt="Non solus logo"/>
        </main>
    )
}

export default Loading