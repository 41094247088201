import React, {useEffect} from "react";
import styles from "../css/gre.module.css";

const CloseWindowButton = (props: { autoclose :boolean}) => {

    // auto-close in 7 seconds
    useEffect(()=>{
        if (props.autoclose){
            setTimeout(()=>{
                window.close();
            }, 7000)
        }
    }, [props.autoclose])


    return (
        <button
            className={`${styles.esButton} ${styles.primary} ${styles.small} es-js-dialog-close`}
            type="submit"
            onClick={(event) => {
                window.close();
            }}>
            <span>Return to journal</span>
            {/*<svg focusable="false" height="24" viewBox="0 0 54 128" width="24">*/}
            {/*    <path d="M1 99l38-38L1 23l7-7 45 45-45 45z" />*/}
            {/*</svg>*/}
        </button>
    )
}

export default CloseWindowButton