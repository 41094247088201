import React, {Dispatch, SetStateAction} from "react";
import {FieldErrors, FieldValues, UseFormClearErrors, UseFormSetValue} from "react-hook-form";
import {Question} from "@tnt/gre-api-ts-client"
import styles from "../css/gre.module.css";
import RadioButtonComponent from "./RadioButtonComponent";
import CustomCheckboxComponent from "./CustomCheckboxComponent";
import HTMLReactParser from "html-react-parser";

const  RenderQuestion = (
    { questionList, register, errors, clearErrors, children }:
        { questionList: Array<Question>, register: any, errors: FieldErrors<FieldValues>,
            clearErrors: UseFormClearErrors<FieldValues>,
    children: { setInitialSaveButtonState: Dispatch<SetStateAction<boolean>>, setValue: UseFormSetValue<FieldValues>}}) => {

    let rendering = null;
    let questions = Array.from(questionList)

    rendering = questions.map(question => {
            let renderComponent;

            switch (question.questionType) {
                case "radio":
                    renderComponent = RadioButtonComponent(question, register, errors, children.setInitialSaveButtonState);
                    break;
                case "multi-radio":
                    renderComponent = CustomCheckboxComponent(question, register, errors, clearErrors, children.setValue);
                    break;
            }

            return (
                <>
                    <div className={`${styles.contentLabel}`}>{HTMLReactParser(question.questionText || "")}</div>
                    <div className={`${styles.contentLabelChildren}`}>{HTMLReactParser(question.questionSubText || "")}
                        {renderComponent}
                    </div>
                </>
            )
        }
    )
    return (<>{rendering}</>);
}

export default RenderQuestion