import {FieldErrors, FieldValues, UseFormClearErrors} from "react-hook-form";
import styles from "../css/gre.module.css";
import React from "react";

const  CustomAdvanceChkBoxWithText = ( {name, option, register, optionsDisabled, errors, clearErrors}:
                                           {name: string, option: any, register: any, optionsDisabled: boolean
                                               errors: FieldErrors<FieldValues>, clearErrors: UseFormClearErrors<FieldValues>}) => {

    return (
        <label className={`${styles.esCheck} ${styles.small}`}>
            <input className={`${styles.esCheckInput}`}
                   name={name}
                   type="checkbox"
                   value={option.option}
                   onClick={(event => {
                       if(!event.currentTarget.checked) {
                           clearErrors('selfDescribe-' + name)
                       }
                   })}
                   disabled={optionsDisabled}
                   {...register(name, {required: "Response is required"})}/>
            <span style={{display: "inline-flex"}}>
                                {option.option}
                <input name={"selfDescribe-"+name}
                       disabled={optionsDisabled}
                       type="text" maxLength={75}
                       className={`${styles.esInputText}`}
                       style={{width: "160px", height: "20px", marginLeft: "12px", fontSize: "14px"}}
                       onKeyUp={e => {
                           const value = e.currentTarget.value;
                           if(!value.match('^[A-Za-z0-9? ,-]+$')) {
                               e.currentTarget.value = value.substr(0, value.length-1)
                           }
                       }
                       }
                       {...register("selfDescribe-"+name)}
                />
                                <span style={{color: "red"}}>&nbsp;{errors["selfDescribe-"+name]?.message}</span>
                            </span>
        </label>
    );
}

export default CustomAdvanceChkBoxWithText;