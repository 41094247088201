import React, {useState} from "react";
import {Question} from "@tnt/gre-api-ts-client/api";
import {FieldErrors, FieldValues, UseFormClearErrors, UseFormSetValue} from "react-hook-form";
import styles from "../css/gre.module.css";
import CustomAdvanceChkBoxWithText from "./CustomAdvanceChkBoxWithText";


const CustomCheckboxComponent = (question: Question, register: any, errors: FieldErrors<FieldValues>,
                                 clearErrors: UseFormClearErrors<FieldValues>, setValue: UseFormSetValue<FieldValues>): React.ReactNode => {

    const options = question.responseOptions != null ? question.responseOptions : [];
    const name = question.questionName != null ? question.questionName : "";

    const [optionsDisabled, setOptionsDisabled] = useState(question.previousResponse !== undefined && question.previousResponse[0] === 'I prefer not to disclose');

    const handleDisable = (e: any) => {
        setOptionsDisabled(e.currentTarget.checked)
        if(e.currentTarget.checked) {
            // This will unset all other checked boxes.
            // If user does want to change, they will need to reselect all options they want again.
            setValue(name, ['I prefer not to disclose'])
        }

    }

    return (
        <>
            <div id={question.questionName}>
                <ul className={styles.esChecklist}>

                    {renderCheckboxOptions(options, name)}
                    {/*<span style={{color: "red"}}>{errors[name]?.message}</span>*/}
                </ul>
            </div>
        </>
    )

    function renderCheckboxOptions(options: any[], name: string) {

        const opt = Array.from(options)
        return opt.map((option, index) => {
            return (
                <>
                    {option.option === 'I prefer not to disclose' &&
                    <li style={{paddingTop: "16px"}}>
                        <label className={`${styles.esCheck} ${styles.small}`}>
                            <input className={`${styles.esCheckInput}`}
                                   name={name}
                                   type="checkbox"
                                   value={option.option}
                                   onClick={handleDisable}
                                   {...register(name, {required: "Response is required"})}/>
                            <span>{option.option}</span>
                        </label>
                    </li>
                    }
                    {option.option !== 'I prefer not to disclose' && option.option !== 'Self describe' &&
                    <li>
                        <label className={`${styles.esCheck} ${styles.small}`}>
                            <input className={`${styles.esCheckInput}`}
                                   name={name}
                                   type="checkbox"
                                   value={option.option}
                                   disabled={optionsDisabled}
                                   {...register(name, {required: "Response is required"})}/>
                            <span>{option.option}</span>
                        </label>
                    </li>
                    }
                    {option.option === 'Self describe' &&
                    <li>
                        <CustomAdvanceChkBoxWithText name={name} option={option} register={register}
                                                     optionsDisabled={optionsDisabled} errors={errors} clearErrors={clearErrors}/>
                    </li>
                    }
                </>
            )
        });
    }
}

export default CustomCheckboxComponent;
