import { UserInfo } from '@tnt/gre-api-ts-client';
import { QuestionnaireResponse } from '@tnt/gre-api-ts-client/api';
import { useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';

import { trackPageLoad } from '../analytics/aa-utils';
import { getQuestionnaire, parseQueryParameters, saveQuestionnaireResponse } from '../api/backendApi';
import { oauth2 } from '../api/oauth';
import styles from '../css/gre.module.css';
import GRERegistrationForm from './GRERegistrationForm';
import Loading from './Loading';

const GREDialog = (props: { name: string }) => {

    const parser = new UAParser();

    // @TODO Fix TNT-950
    const IAmElsEmployee = new Array("I am an Elsevier employee")

    parseQueryParameters()
    const [showThankyouScreen, setShowThankyouScreen] = useState<boolean>(false);
    const [, setShowElsAckScreen] = useState<boolean>(false);
    const [jsonData, setJsonData] = useState<QuestionnaireResponse>({});
    let peopleId: number = Number(sessionStorage.getItem("peopleId") || "");
    let journalCode: string = sessionStorage.getItem("emJournalCode") || "";
    let questionSet: string = sessionStorage.getItem("questionSet") || "";

    const [accessKey, setAccessKey] = useState("")
    const userInfo : UserInfo= { peopleId: peopleId, journalCode: journalCode}

    const checkAndGetQuestionnaire = (token:string) =>{

        trackPageLoad({
            'pageName': 'questionPage',
            'userAgent': parser.getBrowser().name,
            'accessType': 'gre:REG_GUEST',
        }, 'questionPage', 'gre:questionPage');

        //Apparently js has some problems with equal comparison for objects
        // {} == {} > False; {} === {} > False
        //So we do the following instead:
        if(JSON.stringify(jsonData) === "{}"){
            getQuestionnaire(userInfo, questionSet, token).then(r => {
                if (r !== undefined && r.questionnaire !== undefined){
                    setJsonData(r)
                    // Check if all questions are answered
                    let previouslyAnswered = r.questionnaire.every((section)=>{
                        if(section.questionsList !== undefined){
                            return section.questionsList.every((q=>{
                                return q.previousResponse !== undefined || q.previousResponse!== ""
                            }))
                        }
                    })

                    let elsEmployee = JSON.stringify(r.questionnaire).search(IAmElsEmployee[0]) !== -1

                    let flowPointLogin = sessionStorage.getItem("flowPoint") === "login"

                    if(elsEmployee && flowPointLogin){
                        console.log("ELS employee detected. Submitting 'I am els employee' on their behalf.");
                        saveQuestionnaireResponse({
                            userInfo:  userInfo,
                            gender: (IAmElsEmployee as any),
                            ethnicity: (IAmElsEmployee as any),
                            race: (IAmElsEmployee as any),
                        }, questionSet, accessKey).then(()=>{
                            console.log("Submitted for ELS employee.")
                            setShowElsAckScreen(true);
                        })
                    }
                    if(previouslyAnswered && flowPointLogin){
                        console.log("Already has answers for all questions. Closing window because flowpoint=login");
                        window.close();
                    }
                }
            }).catch((e)=>{
                console.error("Failure getting questionnaire.")
                console.error(e)
                window.location.assign("/error")
            })
        }
    }

    useEffect(()=>{

        if(accessKey === ""){
            oauth2()
                .then((token)=>{
                    setAccessKey(token)
                    return token
                })
                .catch((e)=> {
                    console.error("Failure during oauth flow.")
                    console.error(e)
                    window.location.assign("/error")
                })
        } else {
            checkAndGetQuestionnaire(accessKey)
        }

    })

    let content;

    //Show the loading screen if you have no questionnaire.
    if (JSON.stringify(jsonData) === "{}"){
        content = <Loading/>
    } else {
        content =
            <div aria-labelledby="dialog-complex1-label" aria-modal="true"
                 className={`${styles.esDialog} ${styles.large}`}
                 id={props.name} role="dialog">
                <div className={`${styles.esDialogWindow} es-js-dialog-window`}>
                    <div className={`${styles.esDialogContent}`}>
                        <GRERegistrationForm showThankyouScreen={showThankyouScreen}
                                             showElsAckScreen={JSON.stringify(jsonData).search(IAmElsEmployee[0]) !== -1}
                                             data={jsonData}
                                             questionSet={questionSet}
                                             user={userInfo}
                                             accessToken={accessKey}>
                            {{
                                'setShowThankyouScreen': setShowThankyouScreen
                            }}
                        </GRERegistrationForm>
                    </div>
                </div>
            </div>
    }


    return (
        <main className={`${styles.esHtmlBody}`}>
            {content}
        </main>
    )
}

export default GREDialog